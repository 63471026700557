import {
  Box,
  Button,
  Chip,
  CircularProgress,
  createTheme,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabPanel from "../../components/UI/tabPanel";
import { commonTableOptions } from "../../constants";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  a11yProps,
  getIssueStatusBgColor,
  handleError,
  infoToast,
  parseAndFormateDate,
} from "../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import IssueDetailModal from "./issueDetailModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ProviderSettingsInfoModal from "../Providers/providerSettingsInfoModal";
import EmailIcon from "@mui/icons-material/Email";
import EmailReportModalAppIssues from "./emailReportModalAppIssues";
import AddIcon from "@mui/icons-material/Add";
import CreateTicketModal from "./createTicketModal";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { parse } from "date-fns";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider, useTheme } from "@mui/styles";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";

const AppIssues = () => {
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const activeTab: any = params.get("tab") || 1;
  const [value, setValue] = useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [issuesData, setIssuesData] = useState<any>([]);
  const privateInstance = useAxiosInstance();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [viewMode, setViewMode] = useState("");
  const [showIssueDetailModal, setShowIssueDetailModal] = useState(false);
  const [showProviderSettingsInfoModal, setShowProviderSettingsInfoModal] =
    useState(false);
  const [activeLocationId, setActiveLocationId] = useState<string>("");
  const [showCreateTicket, setShowCreateTicket] = useState(false);
  const [searchBy, setSearchBy] = useState("location_id");
  const [searchText, setSearchText] = useState("");
  const theme: any = useTheme();
  const windowWidth = window?.innerWidth;
  const user: AuthenticatedUser = useUserInfo();

  const getIssuesList = async () => {
    let url = `${API_VERSION.V1}/issues`;

    if (value !== 2) {
      //for first two tabs
      if (params.get("issueId")) {
        url = `${url}/${params.get("issueId")}`;
      } else {
        url = `${url}?type=${value === 0 ? "ongoing" : "resolved"}`;
        if (user?.role === USER_ROLE.VIEWER) {
          url = `${url}&assigned_to=${user?.user_id}`;
        }
      }
    } else {
      // for third tab
      if (searchBy === "issue_id") {
        url = `${url}/${searchText}`;
      }
      if (searchBy === "location_id") {
        url = `${url}?location_id=${searchText}`;
      }
    }
    //reset local state on api call
    setIssuesData([]);

    setLoading(true);
    try {
      const result = await privateInstance.get(url);
      if (result.data?.status === API_RESULT.SUCCESS) {
        if (Array.isArray(result.data.response)) {
          //type open or date range
          setIssuesData(result.data.response);
        } else {
          //when data is fetched by issueid
          let res: any = [];
          if (Object.keys(result.data.response)?.length > 0) {
            res.push(result.data.response);
          }
          setIssuesData(res);
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (value !== 2) {
      //dont load data on tab load for third tab
      getIssuesList();
    } else {
      //reset states for third tab
      setSearchText("");
      setSearchBy("location_id");
      setIssuesData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const columns = [
    {
      name: "issue_id",
      label: "ISSUE ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "issue_type",
      label: "ISSUE TYPE",
      options: {
        filter: true,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "issue_sub_type",
      label: "CATEGORY",
      options: {
        filter: true,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "follow_up_date",
      label: "FOLLOW UP",
      options: {
        filter: activeTab === 1 ? true : false,
        sort: false,
        searchable: false,
        display: false,
      },
    },
    {
      name: "assigned_team",
      label: "ASSIGNED TEAM",
      options: {
        filter: true,
        sort: false,
        searchable: false,
        display: false,
        filterOptions: {
          renderValue: (val: string) => {
            return val?.split("_")?.join(" ");
          },
        },
      },
    },
    {
      name: "location_id",
      label: "LOCATION ID",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Box onClick={(e: any) => e.stopPropagation()} display="flex">
              <Typography
                sx={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#0058ff",
                  textAlign: "left",
                  minWidth:
                    windowWidth > theme.breakpoints.values.sm
                      ? "180px"
                      : "auto",
                }}
                component="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setActiveLocationId(value);
                  setShowProviderSettingsInfoModal(true);
                }}
                fontSize={12}
                variant="body2"
              >
                {value}
              </Typography>
              <CopyToClipboard
                text={value}
                onCopy={() => infoToast("Copied to clipboard")}
              >
                <Tooltip title="Copy Location ID">
                  <IconButton
                    size="small"
                    sx={{
                      ml: 0.1,
                    }}
                  >
                    <ContentCopyIcon
                      fontSize="small"
                      sx={{
                        width: "14px !important",
                        height: "14px !important",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          );
        },
      },
    },
    {
      name: "provider_name",
      label: "PROVIDER NAME",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Typography fontSize={12} minWidth={160} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "description.text",
      label: "DESCRIPTION",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box minWidth={{ xs: "auto", md: "220px" }}>
              <Typography variant="body2" fontSize={12}>
                {value?.length > 60 ? value.substr(0, 60) + "..." : value}
              </Typography>
              {issuesData[tableMeta.rowIndex]?.description?.images?.length > 0
                ? issuesData[tableMeta.rowIndex]?.description.images.map(
                    (x: string, i: number) => {
                      return (
                        <Link
                          sx={{ pl: i === 0 ? 0 : 1 }}
                          key={i}
                          target="_blank"
                          href={x}
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          Image_{i + 1}
                        </Link>
                      );
                    }
                  )
                : null}
            </Box>
          );
        },
      },
    },
    {
      name: "issue_status",
      label: "STATUS",
      options: {
        filter: true,
        filterOptions: {
          renderValue: (val: string) => {
            return val?.split("_")?.join(" ");
          },
        },
        sort: false,
        searchable: false,
        // display: value === 0 ? true : false,
        customBodyRender: (value: any) => {
          return (
            <Chip
              size="small"
              label={value?.split("_")?.join(" ")}
              sx={{
                backgroundColor: getIssueStatusBgColor(value),
                padding: "6px",
                color: ["open", "reopened"].includes(value) ? "#fff" : "#000",
                textTransform: "capitalize",
              }}
            />
          );
        },
      },
    },
    {
      name: "created_at",
      label: "CREATED AT",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2" fontSize={12}>
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "modified_at",
      label: "MODIFIED AT",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        display: value === 1 ? true : false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2" fontSize={12}>
              {value?.split(".")[0]}
            </Typography>
          );
        },
      },
    },
    {
      name: "respondent_actions",
      label: "RESPONDENT ACTIONS",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        display: value === 1 ? true : false,
        customBodyRender: (value: any) => {
          const d = value[value.length - 1];
          if (!d) return;
          return (
            <Box minWidth={{ xs: "auto", md: "220px" }}>
              <Stack direction="row">
                <Tooltip title={`Updated By: ${d.updated_by}`}>
                  <InfoIcon color="primary" fontSize="small" />
                </Tooltip>
                <Typography variant="body2" fontSize={12}>
                  {String(d.text).length > 60
                    ? String(d.text).substr(0, 60) + "..."
                    : d.text}
                </Typography>
              </Stack>
            </Box>
          );
        },
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <>
              {issuesData[tableMeta?.rowIndex]?.issue_status?.toLowerCase() ===
              "resolved" ? (
                <Tooltip title="View More">
                  <IconButton
                    color="primary"
                    aria-label="open"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setShowIssueDetailModal(true);
                      setSelectedRowData(issuesData[tableMeta.rowIndex]);
                      setViewMode("readOnly");
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Update">
                  <IconButton
                    color="primary"
                    aria-label="open"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setShowIssueDetailModal(true);
                      setSelectedRowData(issuesData[tableMeta.rowIndex]);
                      setViewMode("readUpdate");
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...(commonTableOptions as any),
    download: false,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    searchPlaceholder: "Issue ID/Provider Name/Location ID/Description",
    setRowProps: (row: any[]) => {
      if (
        activeTab === 1 &&
        row[3] &&
        parse(row[3], "dd-MM-yyyy", new Date()) <= new Date()
      ) {
        return {
          style: { background: "#fed7d7" },
        };
      }
      return {};
    },
    onRowClick: (row: any, index: any) => {
      setShowIssueDetailModal(true);
      setSelectedRowData(issuesData[index?.dataIndex]);
      if (
        issuesData[index?.dataIndex]?.issue_status?.toLowerCase() === "resolved"
      ) {
        setViewMode("readOnly");
      } else {
        setViewMode("readUpdate");
      }
    },
    setTableProps: () => {
      return {
        size: windowWidth > theme.breakpoints.values.sm ? "" : "small",
      };
    },
    responsive: "vertical",
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        //@ts-ignore
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize:
                windowWidth > theme.breakpoints.values.sm ? "14px" : "12px",
            },
            stackedHeader: {
              fontWeight: "bold",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              display: "flex !important",
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                backgroundColor: "#f4f4f4",
              },
            },
          },
        },
      },
    });

  return (
    <div>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        gap={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: { xs: 0, md: 1 }, borderColor: "divider" }}
      >
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Ongoing"
            {...a11yProps(0)}
            onClick={() => navigate(`/app-issues`)}
          />
          <Tab
            label="Resolved"
            {...a11yProps(1)}
            onClick={() => navigate(`/app-issues?tab=2`)}
          />
          {user?.role === USER_ROLE.VIEWER ? null : (
            <Tab
              label="Search"
              {...a11yProps(1)}
              onClick={() => navigate(`/app-issues?tab=3`)}
            />
          )}
        </Tabs>
        <Box>
          <Button
            sx={{ minWidth: "fit-content", mr: 1.5 }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowCreateTicket(true)}
            startIcon={<AddIcon fontSize="small" />}
          >
            Create
          </Button>
          {user?.role === USER_ROLE.VIEWER ? null : (
            <Button
              sx={{ minWidth: "fit-content" }}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => setShowReportModal(true)}
              startIcon={<EmailIcon fontSize="small" />}
            >
              Report
            </Button>
          )}
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <Box mt={1.5}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title=""
              data={issuesData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box mt={1.5}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title=""
              data={issuesData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <>
          <Box display="flex" gap={1.5} mt={1.5}>
            <FormControl fullWidth size="small">
              <InputLabel id="search-by-label">Search By</InputLabel>
              <Select
                labelId="search-by-label"
                name="search-by"
                id="search-by"
                label="Search By"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              >
                <MenuItem value="issue_id">Issue ID</MenuItem>
                <MenuItem value="location_id">Location ID</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              id="search-text"
              label={searchBy.split("_").join(" ")}
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputLabelProps={{
                required: true,
              }}
            />
            <LoadingButton
              sx={{ minWidth: "140px" }}
              size="medium"
              color="primary"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={getIssuesList}
              disabled={searchText?.trim() === ""}
            >
              Search
            </LoadingButton>
          </Box>
          {issuesData?.length > 0 ? (
            <Box mt={3}>
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  title=""
                  data={issuesData}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </Box>
          ) : null}
        </>
      </TabPanel>
      {showIssueDetailModal && (
        <IssueDetailModal
          open={showIssueDetailModal}
          onClose={() => setShowIssueDetailModal(false)}
          data={selectedRowData}
          setData={setSelectedRowData}
          viewMode={viewMode}
          getIssuesList={getIssuesList}
          tableData={issuesData}
          setTableData={setIssuesData}
        />
      )}
      {showProviderSettingsInfoModal && (
        <ProviderSettingsInfoModal
          showProviderSettingsInfoModal={showProviderSettingsInfoModal}
          setShowProviderSettingsInfoModal={setShowProviderSettingsInfoModal}
          activeLocationId={activeLocationId}
          providersData={null}
          setProvidersData={null}
          path="app-issues"
          targetId={
            issuesData?.find((x: any) => x.location_id === activeLocationId)
              ?.issue_id
          }
        />
      )}
      {showReportModal ? (
        <EmailReportModalAppIssues
          show={showReportModal}
          setShow={setShowReportModal}
        />
      ) : null}
      {showCreateTicket ? (
        <CreateTicketModal
          open={showCreateTicket}
          onClose={() => setShowCreateTicket(false)}
          getIssuesList={getIssuesList}
        />
      ) : null}
    </div>
  );
};

export default AppIssues;
